<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h2>{{ $t('usersBlackList') }}</h2>
      <grid-view-table
        :id="'users-alerts'"
        :columns="columns"
        :path="'users-alerts'"
        :update-route="'UpdateUserAlert'"
        :extended-filter-form="false"
      />
    </div>
  </div>
</template>

<script>
  import GridViewTable from '../../components/GridViewTable';
  import { columns } from './grid-views/users-alerts';

  export default {
    name: 'UsersAlertsList',
    components: {
      GridViewTable,
    },
    data: () => {
      return {
        columns
      }
    }
  }
</script>

import { actions } from "../../../components/grid-views/standart-actions-without-view";
import moment from 'moment';
import i18n from "@/plugins/i18n";

export const columns = [
  {
    label: 'userName',
    field: 'user.name',
    width: '16%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userRole',
    field: 'user.role',
    width: '10%',
    formatFn: (value) => {
      if (value === 'customer') {
        return i18n.t('Customer');
      }
      if (value === 'farmer') {
        return i18n.t('Farmer');
      }
      if (value === 'admin') {
        return i18n.t('Admin');
      }
      if (value === 'publisher') {
        return i18n.t('Publisher');
      }
      return '';
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userAddress',
    field: 'user.address',
    width: '30%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userPhone',
    field: 'user.phone',
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userEmail',
    field: 'user.email',
    width: '10%',
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: 'userDateCreate',
    field: 'user.createdAt',
    width: '10%',
    formatFn: (value) => {
      return moment(value).format('DD.MM.YYYY');
    },
    filterOptions: {
      enabled: true,
      placeholder: ' ',
      filterValue: '',
    },
  },
  {
    label: '',
    field: 'actions',
    width: '10%',
    sortable: false,
    actions: [
      ...actions,
    ]
  },
]
